import React from "react"

import Accordion from "../../lib/components/Accordion"
import ContactForm from "../../lib/components/ContactForm"
import Layout from "../../lib/components/Layout"
import PageTitlePanel from '../../lib/components/PageTitlePanel'
import TextTitle from "../../lib/components/TextTitle"
import Storage from '../../lib/storage'

function Page({ location }) {

  // React hook for checking if screen is mobile size
  const [width, setWidth] = React.useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }

  }, []);
  const isMobile = width <= 768;

  return (
    <Layout
      title={Storage.pages.serviceBathroomWaterproofing.metadata.title}
      description={Storage.pages.serviceBathroomWaterproofing.metadata.description}
      metadataScripts={Storage.pages.serviceBathroomWaterproofing.metadata.scripts}
      subPages={[Storage.pages.services.metadata.title]}
      pageUrl={location.pathname}
      fixedButton="backToServices"
      bgNavSelector="titlePanel"
    >
      <PageTitlePanel
        title={Storage.pages.serviceBathroomWaterproofing.metadata.title}
        subtitle={Storage.pages.serviceBathroomWaterproofing.metadata.description}
        backgroundImg={Storage.pages.serviceBathroomWaterproofing.titlePanel.backgroundImg}
        isMobile={isMobile}
        relativeUrl={location.pathname}
      />

      <div className="relative w-full nexus-bg h-page-p v-page-p grid gap-8 select-none">
        <div className="flex flex-col items-center text-center gap-4">
          <TextTitle
            title='Waterproof your bathrooms'
            titleIcon={
              <div className="flex items-center justify-center w-10 h-10 bg-green-600 rounded-full p-2">
                <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path className="nexus-svg" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
            }
          />
          <p className="nexus-text">While it is important to consider appearance when building a new home, it is equally of importance to consider the underlying structure that underpins the home. If these areas are located close to living spaces, such as a living room or bedroom, the need for waterproofing toilets, bathrooms and showers can often help avoid serious damage in the future.</p>
          <p className="nexus-text">Waterproofing is a crucial preventative measure that helps to protect the building from moisture damage and ensure safety. Bathrooms, laundries, and other wet areas are particularly vulnerable to moisture damage, and must be waterproofed or made water resistant in order to comply with <strong>Australian Standard AS 3740-2010 (Waterproofing of domestic wet areas).</strong></p>
          <p className="nexus-text">Building codes in Australia dictate that the following surfaces be professionally waterproofed:</p>
          <ul className="nexus-text text-sm text-left list-disc list-inside grid gap-2">
            <li>Bathroom walls</li>
            <li>Shower walls and floors</li>
            <li>Spa floors</li>
            <li>Bathroom floors on the second storey or above</li>
            <li>Any bathroom floors containing wood</li>
            <li>Steps at the entry of the bath and/or shower</li>
          </ul>
          <p className="nexus-text">Bathrooms pose a challenging environment for builders due to the number of surfaces that need to be waterproofed, ideally prior to the installation of appliances and/or flooring.</p>
          <p className="text-sm nexus-text font-bold">Nexus Commercial Services can waterproof your bathroom across these key areas, placing a membrane beneath the flooring to ensure that the foundations are protected from any splashes and drips that cause water damage. This ensures that the underlying integrity of the bathroom is maintained as it is used over time.</p>
        </div>
        <div className="grid gap-6 text-center">
          <p className="nexus-text uppercase">Ask about our qualifications to see how we can give you the best {Storage.pages.serviceBathroomWaterproofing.metadata.title} services in Melbourne.</p>
          <div className="flex justify-center">
            <div
              role='button'
              tabIndex={0}
              className="group block w-10 h-10 md:animate-bounce cursor-pointer"
              // variants={fadeDown}
              initial="initial"
              // animate={control}
              onClick={() => { if (typeof document !== 'undefined') document.getElementById('bathroomCta').scrollIntoView({ behavior: 'smooth', block: 'center' }) }}
              onKeyUp={() => null}
              onKeyDown={(e) => { e.preventDefault(); if (e.key === 'Enter') if (typeof document !== 'undefined') document.getElementById('bathroomCta').scrollIntoView({ behavior: 'smooth', block: 'center' }) }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path className="nexus-svg-inverted opacity-80 group-hover:opacity-100" d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <Accordion
        faqs={Storage.pages.serviceBathroomWaterproofing.accordion}
      />

      {/* <Cta
        backgroundImg={Storage.pages.serviceBathroomWaterproofing.cta.img}
        title={`Would you like to enquire further about ${Storage.pages.serviceBathroomWaterproofing.metadata.title}?`}
        id={'bathroomCta'}
        btn={<NexusButton title='Contact Us' link='/contact-us' linkType='internal' theme='dark' />}
        type='footer'
      /> */}

      <div id='bathroomCta' className="relative w-full h-page-p v-page-p nexus-bg">
        <div className="flex flex-col gap-4">
          <div className="grid gap-4">
            <h1 className="font-bold uppercase nexus-text">Enquiries Form</h1>
            <p className="font-normal nexus-text">Submit your enquiry below and we will reach out to you directly.</p>
          </div>
          <ContactForm />
        </div>
      </div>

    </Layout>
  )
}

export default Page
